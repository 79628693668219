import { createI18n } from 'vue-i18n';
import { get } from 'lodash';

// Import locale messages
import en from '../locales/en.json';
import fr from '../locales/fr.json';

const messages = {
  en,
  fr,
};

function getBrowserLanguage() {
  const languages = ['en', 'fr'];
  const navigatorLanguage = navigator.language || navigator.userLanguage;
  const primaryLanguage = navigatorLanguage.split('-')[0];
  return languages.includes(primaryLanguage) ? primaryLanguage : 'fr'
}

const i18n = createI18n({
  legacy: false,
  locale: getBrowserLanguage(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
  messages, // set locale messages
});

export default i18n;
